export default {
	data: () => ({
		langEs: {
			header: {
				courses: 'Cursos',
				shop: 'Tienda',
				specialists: 'Especialistas',
				about: 'Sobre nosotros',
				blog: 'Blog',
				promotions: 'Promociones',
				login: 'Acceso',
				registration: 'Registro',
				shopHidden: 'solo para usuarios registrados',
				shopClose: 'No disponible en tu región',
				dashboard: 'Mi cuenta'
			}
		}
	})
}
