export default {
	data: () => ({
		langEs: {
			header: {
				courses: 'Cursos',
				shop: 'Tienda',
				specialists: 'Especialistas',
				about: 'Sobre nosotros',
				blog: 'Blog',
				promotions: 'Promociones',
				login: 'Acceso',
				registration: 'Registro',
				shopHidden: 'solo para usuarios registrados',
				shopClose: 'No disponible en tu región'
			},
			slider: {
				btn: 'Seleccionar curso'
			},
			about: {
				video: { title: 'Video', desc: 'Cursos prácticos y teóricos' },
				support: { title: 'Ayuda', desc: 'Consulta a los mejores especialistas' },
				web: { title: 'WEB', desc: 'Crea tu propia página web con reserva de citas online' },
				results: { title: 'Resultados', desc: 'Certificados profesionales' },
			},
			support: {
				question: '¿Necesitas ayuda?',
				desc: 'Siempre estamos disponibles para ayudarte y responder cualquier pregunta',
				btn: 'Contáctanos'
			},
			courses: {
				title: 'Nuestros cursos',
				left: 'Cosmetología',
				right: 'Medicina Estética',
				btn: 'Ver todos los cursos'
			},
			author: {
				title: 'Sobre la Dr.Lica',
				btn: 'Ver historia completa'
			},
			offers: {
				title: {
					txt: 'Ofertas',
					color: 'especiales'
				}
			}
		}
	})
}
