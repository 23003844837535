export default {
	data: () => ({
		langEs: {
			author: {
				btn: 'Ver historia completa'
			}
		}
	})
}

