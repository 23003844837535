export default {
	data: () => ({
		langEs: {
			title: {
				text: '¿Tienes alguna',
				color: 'pregunta?'
			},
			desc: {
				text: 'Aquí puedes encontrar las preguntas más frecuentes',
				color: 'Preguntas',
				text2: 'Estaremos a tu lado durante todo el proceso de estudio'
			},
			info: 'Para más información contáctanos',
			btnText: 'Deja una solicitud'
		}
	})
}

