const domain = process.env.VUE_APP_BASE_API;

export default {
  namespaced: true,
  state: {
    user: {
      userLogin: '', // 'admin'
      userName: '', // 'Admin'
      token: '',
      userID: '',
      specialization: '',
      avatar: '/img/avatar-dashboard.png',
      profile: null,
      country: {},
    }
  },
  getters: {
    isLogin(state) {
      return state.user.token.length > 0; 
    },
    checkCountry(state, getters){
      let UserCountry = getters.user.profile?.profile?.city;
      let whiteList = [
        "Moldova, Republic of",
        "Romania",
        "Russian Federation"
      ]
      return whiteList.includes(UserCountry);
    },
    checkUserType(state, getters) {
      let userType = getters.user.profile?.profile?.type;
      let whiteList = [
        "cosmetology",
        "doctor"
      ]
      return whiteList.includes(userType); 
    },
    user(store) {
      return store.user
    }
  },
  mutations: {
    user(state, {name, value}) {
      state.user[name] = value;
    }
  },
  actions: {
    initializeCountry: async ({commit}) => commit('user', { name: 'country', value: await apiLocalTime() }),
    user({ commit }, payload) {
      commit('user', payload)
    }
  }
}


async function apiLocalTime() {
  try {
    let request = await fetch(`${domain}/time/`, {
      headers:{
        'Content-Type': 'application/json'
      }
    });

    if(!request.ok){
      throw request;
    }
    let responce = await request.json();
    return responce;
  }
  catch(err){
    console.error(err);
  }
}