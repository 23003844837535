<template>
  <div class="login__modal active">
    <div class="loader hide" ref="loader">
    </div>
    <form @submit.prevent>
      <div class="container">
        <div class="wrapp_logo">
          <router-link to="/" class="logo"></router-link>
          <SelectorLang color="#930684" style="height: 42px"/>
        </div>
        <template v-if="!forgotPass">
          <span class="err" v-if="badRequest">{{$t('login.badDate')}}</span>
          <strong class="title" :style="{ 'color': badRequest ? 'red' : 'black' }">{{ $t('login.title') }}</strong>
          <div class="inp">
            <input type="text" :class="{'warning': !regexp.email.status }" placeholder="Email" v-model="login" @keyup.enter="sendLogin">
          </div>
          <div class="inp">
            <!-- <span class="err" v-if="!regexp.password.status">{{ textError.password[langStorage] }}</span> -->
            <input type="password" autocomplete="off" :class="{'warning': !regexp.password.status }" :placeholder="$t('login.password')" v-model="password" @keyup.enter="sendLogin">
          </div>
          <div class="wrapp_checkbox">
            <label class="checkbox_label">
              <input class="checkbox" type="checkbox" v-model="saveLoginAndPass">
              <span>{{selectLang.savePass}}</span>
            </label>
          </div>
          <div class="btm">
            <div class="register__btn" @click="forgotPass = true">
              {{$t('login.forgotPass')}}
            </div>
            <button @click="sendLogin" type="button">
              {{ $t('login.login') }}
            </button>
          </div>
        </template>
        <template v-else>
          <template v-if="dataResetPass.showMessage">
            <strong class="title">{{ $t('login.recoveryPass')}}</strong>
            <div class="inp">
              <input type="text" :class="{'warning': !regexp.email.status }" placeholder="Email" v-model="login" @keyup.enter="resetPass">
            </div>
            <div class="btm">
              <div class="register__btn" @click="forgotPass = false">
              {{ $t('login.login') }}
              </div>
              <button :disabled="dataResetPass.btnBlock" @click="resetPass" type="button">
              {{dataResetPass.btnBlock ? 'Loading' : $t('login.btnRecover')}}
              </button>
            </div>
          </template>
          <template v-else>
            <h2>{{$t('login.message')}}: <br><span style="color:#891E82; font-size:18px">{{login}}</span></h2>           
          </template>
        </template>
      </div>
      <div class="btn_registration" @click="$router.push({name: 'Registration', params: {lang: langStorage}})">{{ $t('login.registration') }}</div>
    </form>
    <div class="img__cont">
      <div class="close__btn" @click="$router.push({ name: 'Home', params: {lang: langStorage}})">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.0022 9.19992L0.80422 0.00190735L0.00437312 0.801755L9.20238 9.99976L0.00390625 19.1982L0.803753 19.9981L10.0022 10.7996L19.2007 19.9981L20.0006 19.1982L10.8021 9.99976L20.0001 0.801756L19.2002 0.00190886L10.0022 9.19992Z" fill="white"/>
        </svg>
      </div>
      <img src="/img/login-photo.jpg" alt="">
    </div>
  </div>
</template>

<script>
import LangRu from '@/lang/Login/ru' 
import LangRo from '@/lang/Login/ro' 
import LangEng from '@/lang/Login/eng'
import LangEs from '@/lang/Login/es'

import SelectorLang from '@/components/SelectorLang'
import { mapActions, mapGetters } from 'vuex';

const domain = process.env.VUE_APP_BASE_API;

export default {
  mixins: [LangRu, LangRo, LangEng, LangEs],
  components: {
    SelectorLang
  },
  mounted(){
    let logInData = localStorage.getItem("logInData");
    if(logInData != null){
      logInData = JSON.parse(logInData);
      this.login = logInData.email;
      this.password = logInData.password;
    }
  },
  data: () => ({
    login: '',
    password: '',
    badRequest: false,
    regexp: {
      email: {
        regexp: /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i,
        status: ' '
      },
      password: {
        status: ' '
      }
    },
    forgotPass: false,
    translate: {
      ru: {
        message: "Проверьте пожалуйста почтовый ящик"
      },
      ro: {
        message: "Verificați vă rog poșta electronică"
      },
      en: {
        message: "Please check your mailbox"
      },
      es: {
        message: "Por favor, revisa tu buzón de entrada"
      }
    },
    dataResetPass: {
      showMessage: true,
      btnBlock: false
    },
    saveLoginAndPass: false
  }),
  methods: {
    googleEventLoginComplete(){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event' : 'Login_Complete'
      })
    },
    googleEvenetResetPassword(){
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event' : 'Forgot_Complete'
      });
    },
    ...mapActions({
      editUser: 'user/user',
    }),
    async sendLogin(e){
     this.login = this.login.trim();
     this.password = this.password.trim();
      if(this.saveLoginAndPass){
        this.saveDataForLocalStorage();
      }
      if(this.validator()) {
        e.target.disabled = true;
        const response = await fetch(`${domain}/auth/token/login/ `, {
          method: 'POST',       
          headers: {
            'Content-Type': 'application/json',         
          },
          body: JSON.stringify({ 
            'password': this.password,
            'username': this.login.toLowerCase()
          }) 
        });
        if(response.ok){
          const res = await response.json(); // parses JSON response into native JavaScript objects
          
          const responseUres = await fetch(`${domain}/auth/users/me/`, {
            headers : {
              Authorization: `Token ${res.auth_token}` 
            } 
          });
          const loader = this.$refs.loader;
          if(loader){
            loader.classList.remove('hide')
          }
          const dataUser = await responseUres.json();
          this.editUser({ name: 'userLogin', value: dataUser.username });
          this.editUser({ name: 'userID', value: dataUser.id });
          this.editUser({ name: 'token', value: res.auth_token });

          let profile = await fetch(`${domain}/accounts/users/${dataUser.id}/`);
          profile = await profile.json();
          this.editUser({name: 'profile', value: profile});
          this.editUser({name: 'userName', value: profile.profile.name});
          this.editUser({name: 'specialization', value: profile.profile.specialization});
          this.editUser({name: 'avatar', value: profile.profile.image});
          this.$router.push({name: 'courses', params: {lang: this.langStorage}});
          this.googleEventLoginComplete();             
        }
        this.badRequest = true;
        this.login = '';
        this.password = '';

        setTimeout(() => e.target.disabled = false, 3000);
      }
    },
    validator(){
      this.regexp.email.status = this.regexp.email.regexp.test(this.login) || this.login.toLowerCase() == "admin";
      this.regexp.password.status = this.password.length >= 5;

      for (let key in this.regexp) {
        if(this.regexp[key].status == false){
          return false;
        }
      }
      return true;
    },
    async resetPass(){
     this.regexp.email.status = this.regexp.email.regexp.test(this.login);
     if(this.regexp.email.status) {
      this.dataResetPass.btnBlock = true;
      try {
        await fetch(`${domain}/${this.langStorage}/api/password_reset/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            "email": this.login
          })
        });
        this.googleEvenetResetPassword();
      }
      catch(er){
        console.error(er);
      }
      this.dataResetPass.btnBlock = false;

      this.dataResetPass.showMessage = false;

     }
    },
    saveDataForLocalStorage(){
      localStorage.setItem("logInData", JSON.stringify({
        email: this.login,
        password: this.password
      }));
    }
  },
  computed: {
    ...mapGetters({
      langStorage : 'languages/getLang'
    }),
    selectLang() {
      if(this.langStorage == 'ro'){
        return this.langRo;
      }
      else if(this.langStorage == 'ru'){
        return this.langRu;
      }
      else if(this.langStorage == 'es'){
        return this.langEs;
      }
      return this.langEng;
    }
  }
}
</script>

<style lang="scss">
  @media(max-width: 430px){
    .login__modal .btn_registration{
      margin-top: 7em !important;
    }
  }
  .login__modal form .container .btm button {
    outline: none;
  }
  .check {
    background: url("/img/svg/check.svg") no-repeat white right / 30px !important;
    background-position: 95% !important;
  }
  .cross {
    background: url("/img/svg/cross.svg") no-repeat white right / 30px !important;
    background-position: 95% !important;
  }

  .login__modal .logo {
    background: url("/img/logo2.svg") no-repeat !important;
    width: 150px !important;
    height: 50px !important;
  }

  .login__modal {
    .wrapp_logo {
      margin-top: 10%;
    }
  }
  .login__modal {
    .warning {
      border: 1px solid rgba(255, 0, 0, 0.493) !important;
      background: rgba(255, 0, 0, 0.034) !important;
    }
    .warning-text {
      color: red !important;
    }
    span.err {
      display: block;
      font-size: 12px;
      font-weight: 800;
      padding-left: 10px;
      color: #ff00009e;
    }
    .container {
      width: 100%;
      @media(min-width: 1025px) {
        padding: 36px;
      }
    }
    strong.title {
      display: block;
    }
    .btn_registration {
      width: 100%;
      height: 60px;
      margin-top: auto;
      font-weight: 500;
      font-size: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #891E82;
      color: white;
      cursor: pointer;
    }

    .checkbox_label {
      display: flex;
      align-items: center;
      span {
        margin-left: 10px;
        font-size: 13px;
        color: #930684;
      }
      .checkbox {
        width: 17px;
        height: 17px;
        margin-left: 5px;
      }
    }
    .wrapp_checkbox {
      margin-bottom: 20px;
    }

    @media(max-width: 430px) {
      form {
        padding: 0;
        .container {
          padding: 0 16px;             
          .inp {
            height: 60px;
          }
        }
      }
    }
  }

  @media(max-width: 430px){
    .login__modal form .container .btm button, .login__modal form .container .btm .register__btn {
      font-size: 16px;
    }
    .login__modal form .container strong {
      font-size: 40px;
      margin-bottom: 50px;
    }
    .login__modal form .container .btm button {
      line-height: unset;
      padding: 20px 25px;
    }
  }
  @media (max-width: 1385px) and (min-width: 430px) {
    .login__modal {
      height: auto !important;
    }
    .login__modal form {
      padding: 20px !important;
    }
  }
  @media (max-width: 1024px) and (min-width: 430px) {
    .login__modal form .container .btm .register__btn {
      font-size: 17px !important;
    }
    .login__modal form .container .btm button {
      font-size: 14px !important;
    }
  }
  .hide{
    display: none;
  }
  .loader{
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('/img/lica-purple-loader.gif') 50% 50% no-repeat;
    backdrop-filter: blur(2px);
  }
</style>
