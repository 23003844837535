export default {
	data: () => ({
		langEs: {
			title: 'Sobre nosotros',
			left: 'Fuente de conocimiento para tu crecimiento profesional',
			right: 'Todos tenemos el poder y el talento para alcanzar grandes metas. Te ayudamos a encontrar la manera de desarrollarte y alcanzar tus objetivos en la profesión, tal y como lo deseas',
			author: {
				title: 'Sobre la Dr.Lica',
				btn: 'Ver historia completa'
			},
			breadCrumbs: {
				home: 'Inicio',
				about: 'Sobre nosotros'
			}
		}
	})
}


