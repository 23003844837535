export default {
	data: () => ({
		langEs: {
			btnPath: {
				home: 'Inicio',
				category: 'Cursos',
			},
			courseMaterials: 'Materiales del curso',
			courseTitle: {
				text: 'Curso',
				color: ' Contenido'
			},
			courseDesc: 'Lecturas online, tareas prácticas, talleres y master classes te están esperando',
			chapters: 'Teoría',
			modules: 'Vídeos prácticos'
		}
	})
}

