export default {
	data: () => ({
		langEs: {
			form: {
				title: { text1: 'Cuéntanos sobre tus', color: ' objetivos ', text2: '' },
				desc: 'Te ayudaremos a elegir el programa perfecto para ti.',
				consent: { text1: 'Acepto los términos y ', link: 'condiciones ', text2: 'del uso de datos personales' },
				country: 'País',
				name: 'Nombre y Apellidos',
				phone: 'Teléfono',
				btn: 'Deja una solicitud'
			}
		}
	})
}
