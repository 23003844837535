export default {
	data: () => ({
		langEs: {
			footer: {
				courses: 'Cursos',
				shop: 'Tienda',
				specialists: 'Especialistas',
				about: 'Sobre nosotros',
				blog: 'Blog',
				login: 'Acceso',
				registration: 'Registro',
				terms: 'Términos y Condiciones',
				provisions: 'Política de privacidad',
				message: 'Déjanos un mensaje',
				desc: 'La primera Academia de belleza especializada en cosmetología y medicina estética. Profesionales certificados en el sector te proporcionarán los mejores materiales para ayudarte a lograr tus objetivos.',
				policy: 'Términos de uso'
			}
		}
	})
}
